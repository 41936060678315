import React, {
    Component
} from 'react';
import Layout from '../components/layout';


class Contact extends Component{


    
    render() {
        return ( 
            <Layout>
            <p>404 Can't find the page you are looking for.</p>
          </Layout>

        )
    }
}

export default Contact